import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import {
  Box, Button, Text,
} from 'grommet';
import { FormNext } from 'grommet-icons';
import { Sidebar } from 'react-pro-sidebar';
import ReactTooltip from 'react-tooltip';
import { useSpring, animated } from '@react-spring/web';

import {
  FullHeightBox, Container,
} from '@Components/Control';
import { paths } from '@Components/configs';

import {
  initiateCyclopsCompanyOrganizationsRequest,
  setCyclopsActiveOrganizationRequest,
} from '@Actions';

import AuthFooter from './AuthFooter';
import { MenuDropdownSelect } from './HeaderComponent';
import {
  StyledMenuItem,
  StyledSVG,
  StyledMenu,
  StyledHr,
  StyledAuthBanner,
  StyledBannerHeader,
  StyledStickyFooter,
} from './StyledAuthNavWrapper';


const NAV_WIDTH = '15rem';
const NAV_COLLAPSED_WIDTH = '4.25rem';

export const AuthBanner = ({
  small,
  image,
  title,
  subTitle,
  textColor,
  navCollapsed,
  bannerChildren = null,
  bannerChildrenPosition = 'bottom',
  adjustLeftPad = false,
  smallBanner = false,
}) => {
  const bottomBannerChildren = bannerChildren && bannerChildrenPosition === 'bottom';
  const rightBannerChildren = bannerChildren && bannerChildrenPosition === 'right';
  const padValue = smallBanner ? '1.5rem' : '2.5rem';
  let padLeft = navCollapsed ? `calc(${padValue} + ${NAV_COLLAPSED_WIDTH})` : `calc(${padValue} + ${NAV_WIDTH})`;
  let padTop = small ? '2.5rem' : '5rem';
  let padBottom = '2.5rem';
  let textSize = small ? '2rem' : '1.75rem';

  if (adjustLeftPad) padLeft = '2.5rem';

  if (smallBanner) {
    padTop = small ? '2rem' : '4.65rem';
    padBottom = small ? '2rem' : '1rem';
    textSize = small ? '1.85rem' : '1.4rem';
  }

  if (small) {
    padLeft = '1.5rem';
  }

  return (
    <StyledAuthBanner
      fill
      data-bg={image}
      background={{ image: `url(${image})` }}
      pad={{
        bottom: padBottom,
        top: padTop,
        right: '2.5rem',
        left: padLeft,
      }}
      className="lazyload fade-out"
    >
      <Box flex direction={rightBannerChildren ? 'row' : 'column'} justify={rightBannerChildren ? 'between' : 'start'}>
        <Box gap="0.5rem" direction="column">
          <Text uppercase size={smallBanner ? '0.75rem' : '1rem'} color={textColor}>{subTitle}</Text>
          <StyledBannerHeader margin="none" size={textSize} weight={600} color={textColor}>
            {title}
          </StyledBannerHeader>
        </Box>
        {rightBannerChildren && (
          <Box direction="column" justify="between" pad={{ top: '1rem' }}>
            {bannerChildren}
          </Box>
        )}
      </Box>
      {bottomBannerChildren && (
        <Box direction="column" pad={{ top: '1rem' }}>
          {bannerChildren}
        </Box>
      )}
    </StyledAuthBanner>
  );
};

AuthBanner.propTypes = {
  small: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  navCollapsed: PropTypes.bool.isRequired,
  bannerChildren: PropTypes.node,
  bannerChildrenPosition: PropTypes.oneOf(['bottom', 'right']),
  adjustLeftPad: PropTypes.bool,
  smallBanner: PropTypes.bool,
};

const MenuSectionHeader = ({
  label,
  textColor,
  collapsed,
}) => {
  const {
    opacity, display, y, maxHeight,
  } = useSpring({
    opacity: collapsed ? 0 : 1,
    display: collapsed ? 'none' : 'block',
    y: collapsed ? -10 : 0,
    maxHeight: collapsed ? '0px' : '53px',
    delay: collapsed ? 0 : 100,
    config: { mass: 0.5, tension: 300, friction: 35 },
  });

  return (
    <animated.div
      style={{
        opacity,
        display,
        y,
        maxHeight,
      }}
    >
      <Box pad={{ vertical: '0.65rem' }}>
        <Text size="0.9rem" weight={600} color={textColor}>
          {label}
        </Text>
      </Box>
    </animated.div>
  );
};

MenuSectionHeader.propTypes = {
  label: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

const MenuItemRenderer = ({
  location,
  renderKey,
  first = false,
  disabled = false,
  label,
  path = null,
  collapsed,
  primaryText,
  highlightText,
  hoverColor,
  buttonHighlight,
  iconColor,
  iconHighlight,
  icons: {
    piracyFlagIcon,
    sentimentHeartIcon,
    liveSearchesIcon,
    pausedSearchesIcon,
    completedSearchesIcon,
    allSearchesIcon,
    customReportsIcon,
    settingsIcon,
    cyclopsAnalyticsIcon,
    cyclopsLiveEventsIcon,
    cyclopsPastEventsIcon,
  },
}) => {
  const selectIcon = (key) => {
    switch (key) {
      case 'live':
        return liveSearchesIcon;

      case 'paused':
        return pausedSearchesIcon;

      case 'completed':
        return completedSearchesIcon;

      case 'all':
        return allSearchesIcon;

      case 'piracy':
        return piracyFlagIcon;

      case 'sentiment':
        return sentimentHeartIcon;

      case 'reports':
        return customReportsIcon;

      case 'analytics':
        return cyclopsAnalyticsIcon;

      case 'live-events':
        return cyclopsLiveEventsIcon;

      case 'past-events':
        return cyclopsPastEventsIcon;

      default:
        return settingsIcon;
    }
  };

  const { opacity, display } = useSpring({
    opacity: collapsed ? 0 : 1,
    display: collapsed ? 'none' : 'block',
    delay: collapsed ? 0 : 100,
  });
  const isActive = location.pathname === path;
  let useIconColor = isActive ? iconHighlight : iconColor;

  if (disabled) useIconColor = '#626970';

  return (
    <StyledMenuItem
      disabled={disabled}
      disabledText="#626970"
      path={path}
      buttonHoverColor={hoverColor}
      buttonHighlight={buttonHighlight}
      primaryText={primaryText}
      highlightText={highlightText}
      iconHighlight={iconHighlight}
      first={first}
      data-for={`${renderKey}-tooltip`}
      data-tip={label}
    >
      <Box
        direction="row"
        gap={collapsed ? '0' : '0.5rem'}
        align="center"
        justify={collapsed ? 'center' : 'start'}
        pad={{ left: collapsed ? '0' : '0.5rem' }}
        height="2.25rem"
        width="100%"
      >
        <Box justify="center" align="center" height="1rem" width="1rem" pad={{ top: '0.15rem' }}>
          <StyledSVG
            className="highlightIcon"
            src={selectIcon(renderKey)}
            height="1rem"
            width="1rem"
            $fillColor={useIconColor}
          />
        </Box>
        {collapsed && (
          <ReactTooltip
            id={`${renderKey}-tooltip`}
            className="sideNavTip"
            arrowColor={buttonHighlight}
            place="right"
            effect="solid"
            delayShow={200}
          />
        )}
        <animated.div style={{ opacity, display }}>
          <Box height="2.25rem" justify="center">
            <Text size="0.9rem" weight={500}>
              {label}
            </Text>
          </Box>
        </animated.div>
      </Box>
    </StyledMenuItem>
  );
};

MenuItemRenderer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  renderKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  collapsed: PropTypes.bool.isRequired,
  first: PropTypes.bool,
  disabled: PropTypes.bool,
  primaryText: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconHighlight: PropTypes.string.isRequired,
  icons: PropTypes.shape({
    piracyFlagIcon: PropTypes.string.isRequired,
    sentimentHeartIcon: PropTypes.string.isRequired,
    liveSearchesIcon: PropTypes.string.isRequired,
    pausedSearchesIcon: PropTypes.string.isRequired,
    completedSearchesIcon: PropTypes.string.isRequired,
    allSearchesIcon: PropTypes.string.isRequired,
    customReportsIcon: PropTypes.string.isRequired,
    settingsIcon: PropTypes.string.isRequired,
    cyclopsAnalyticsIcon: PropTypes.string.isRequired,
    cyclopsLiveEventsIcon: PropTypes.string.isRequired,
    cyclopsPastEventsIcon: PropTypes.string.isRequired,
  }).isRequired,
};

const AuthNavWrapper = ({
  small,
  mixpanel,
  location,
  authPagesConfig,
  bannerProps,
  companyId,
  companyName,
  companyFeatures,
  children,
  companyOrganizations,
  setActiveOrganization,
  fetchCompanyOrganizations,
  activeOrganization = null,
  darkMode = false,
  smallBanner = false,
  hideBanner = false,
  hideFooter = false,
  reportsConfig = null,
  cyclopsConfig = null,
}) => {
  const [navCollapsed, setNavCollapsed] = React.useState(false);
  const { transform } = useSpring({
    transform: `rotate(${navCollapsed ? 180 : 0}deg)`,
    config: { mass: 1.5, tension: 250, friction: 35 },
  });
  const { opacity, display } = useSpring({
    opacity: navCollapsed ? 0 : 1,
    display: navCollapsed ? 'none' : 'block',
    delay: navCollapsed ? 0 : 100,
  });

  const {
    pageBg,
    sideNavBg,
    navBorder,
    navIcons,
    primaryText,
    highlightText,
    hoverColor,
    buttonHighlight,
    defaultIconColor,
    iconHighlightColor,
    bannerBg,
    footerConfig,
    listMenuMap,
    hideBlacklightSearchCompanies,
    darkModeColors,
  } = authPagesConfig;

  const usePageBg = darkMode ? darkModeColors.primaryDarkBg : pageBg;
  const usePrimaryText = darkMode ? darkModeColors.primaryText : primaryText;
  const useNavBg = darkMode ? darkModeColors.containerBg : sideNavBg;
  const useNavBorder = darkMode ? darkModeColors.navBorder : navBorder;
  const useHighlightText = darkMode ? darkModeColors.primaryText : highlightText;
  const useHoverColor = darkMode ? darkModeColors.menuButtonHighlight : hoverColor;
  const iconHighlight = darkMode ? darkModeColors.primaryText : iconHighlightColor;
  const iconFill = darkMode ? darkModeColors.primaryText : defaultIconColor;
  const useSelectButtonPrimary = darkMode ? darkModeColors.primaryText : primaryText;
  const useSelectButtonHighlight = darkMode ? darkModeColors.primaryText : highlightText;
  const useSelectHoverBg = darkMode ? darkModeColors.menuButtonHighlight : hoverColor;

  const cyclopsData = companyFeatures && _.find(companyFeatures, { key: 'cyclops' });
  const isCyclopsDemo = cyclopsData?.config?.demoOnly;
  const companyCyclopsDemoData = cyclopsData && cyclopsConfig?.find(({ metadata }) => (
    metadata?.config?.companyId === companyId && metadata?.config?.companyName === companyName));
  const cyclopsEnabled = isCyclopsDemo
    ? (cyclopsData?.enabled && companyCyclopsDemoData !== undefined)
    : cyclopsData?.enabled;
  const cyclopsAnalyticsDisabled = !isCyclopsDemo || (
    isCyclopsDemo && cyclopsData?.config?.disableAnalytics);
  const cyclopsSettingsDisabled = !isCyclopsDemo || cyclopsData?.config?.disableSettings;
  const cyclopsMenuButtonsDisabled = !isCyclopsDemo && (
    !activeOrganization || !companyOrganizations);
  const cyclopsPageActive = location.pathname.includes('cyclops');
  const organizationsActive = companyOrganizations && activeOrganization;

  React.useEffect(() => {
    if (!companyOrganizations && cyclopsEnabled && !isCyclopsDemo) {
      fetchCompanyOrganizations();
    }
  }, []);

  if (small) {
    return (
      <FullHeightBox background={usePageBg} pad={{ top: '5.2rem' }}>
        {!hideBanner && bannerProps && (
          <AuthBanner
            small
            smallBanner={smallBanner}
            image={bannerBg}
            textColor={bannerProps.textColor}
            title={bannerProps.title}
            subTitle={bannerProps.subTitle}
            bannerChildren={bannerProps.bannerChildren}
            bannerChildrenPosition={bannerProps.bannerChildrenPosition}
            navCollapsed={navCollapsed}
          />
        )}
        <Box flex>
          {children}
        </Box>
        {cyclopsPageActive && !isCyclopsDemo && (
          <StyledStickyFooter align="center" justify="center" pad="1.5rem" background="#2F3C4A">
            <MenuDropdownSelect
              small={small}
              handleSelect={(val) => setActiveOrganization(val)}
              options={(
                companyOrganizations?.map(({ Id, name }) => ({ uid: Id, name }))
              )}
              title="Organization"
              titleAlign="center"
              titleColor={(
                darkMode ? darkModeColors.secondaryText : usePrimaryText
              )}
              selectLabel={organizationsActive ? activeOrganization.name : 'Select organization'}
              buttonLabel="Create an organization"
              buttonLabelColor={darkMode ? darkModeColors.primaryText : highlightText}
              buttonColor={darkMode ? '#454A59' : hoverColor}
              buttonPath={paths.cyclopsOrganizations}
              secondaryButtonLabel="View organizations"
              selectMenuBg={darkMode ? '#454A59' : 'white'}
              selectButtonBg={darkMode ? '#454A59' : 'white'}
              selectButtonPrimary={useSelectButtonPrimary}
              selectButtonHighlight={useSelectButtonHighlight}
              selectHoverBg={useSelectHoverBg}
            />
          </StyledStickyFooter>
        )}
        {(!cyclopsPageActive || isCyclopsDemo) && (
          <AuthFooter
            small
            mixpanel={mixpanel}
            config={footerConfig}
            authPagesConfig={authPagesConfig}
            darkMode={darkMode}
          />
        )}
      </FullHeightBox>
    );
  }

  const searchData = companyFeatures && _.find(companyFeatures, { key: 'search' });
  const searchEnabled = searchData?.enabled;
  const companyReports = reportsConfig && reportsConfig.find(
    ({ metadata }) => metadata?.config?.companyId === companyId,
  );
  const withReports = companyReports?.metadata?.config?.customReports?.length > 0;
  const overviewData = companyFeatures && _.find(companyFeatures, { key: 'overview_embed' });
  const overviewEnabled = overviewData?.enabled && overviewData?.config?.embedId !== null;
  const showCustomReports = reportsConfig
    && _.find(reportsConfig, (({ metadata }) => metadata.config.companyId === companyId));

  const hideSearchFeatures = hideBlacklightSearchCompanies.includes(companyId) || !searchEnabled;
  const hideSideNav = !searchEnabled && !overviewEnabled && !withReports && !cyclopsEnabled;

  return (
    <FullHeightBox background={usePageBg} pad={{ top: smallBanner ? '0' : '3rem' }}>
      <Box direction="row">
        {!hideSideNav && (
          <Sidebar
            collapsed={navCollapsed}
            backgroundColor={useNavBg}
            width={NAV_WIDTH}
            collapsedWidth={NAV_COLLAPSED_WIDTH}
            transitionDuration={300}
            rootStyles={{
              position: 'fixed',
              top: '0',
              height: '100vh',
              borderColor: useNavBorder,
              zIndex: 4,
            }}
          >
            <StyledMenu>
              <Box height="100%" direction="column" justify="between" pad={{ top: '5rem' }}>
                <Box pad={{ horizontal: navCollapsed ? '0.75rem' : '1.5rem', top: '1.5rem' }}>
                  {(overviewEnabled || showCustomReports) && (
                    <>
                      <MenuSectionHeader label="Content Protection Hub" textColor={usePrimaryText} collapsed={navCollapsed} />
                      <Box direction="column" gap="0.5rem">
                        {overviewEnabled && (
                          <MenuItemRenderer
                            first
                            key="piracy-overview"
                            location={location}
                            renderKey="piracy"
                            label="Piracy overview"
                            icons={navIcons}
                            path={paths.productPiracyOverview}
                            collapsed={navCollapsed}
                            hoverColor={useHoverColor}
                            primaryText={usePrimaryText}
                            highlightText={useHighlightText}
                            buttonHighlight={buttonHighlight}
                            iconColor={iconFill}
                            iconHighlight={iconHighlight}
                          />
                        )}
                        {showCustomReports && (
                          <MenuItemRenderer
                            first={!overviewEnabled}
                            key="custom-reports"
                            location={location}
                            renderKey="reports"
                            label="Custom reports"
                            icons={navIcons}
                            path={paths.productCustomReports}
                            collapsed={navCollapsed}
                            hoverColor={useHoverColor}
                            primaryText={usePrimaryText}
                            highlightText={useHighlightText}
                            buttonHighlight={buttonHighlight}
                            iconColor={iconFill}
                            iconHighlight={iconHighlight}
                          />
                        )}
                      </Box>
                      {!hideSearchFeatures && <StyledHr color={useNavBorder} />}
                    </>
                  )}
                  {!hideSearchFeatures && (
                    <>
                      <MenuSectionHeader label="Blacklight" textColor={usePrimaryText} collapsed={navCollapsed} />
                      <Box direction="column" gap="0.5rem">
                        {listMenuMap && listMenuMap.map(({ uid, display: displayLabel }, i) => (
                          <MenuItemRenderer
                            key={uid}
                            first={i === 0}
                            location={location}
                            renderKey={uid}
                            label={displayLabel}
                            icons={navIcons}
                            path={paths.productListSearches.replace(':status', uid)}
                            collapsed={navCollapsed}
                            hoverColor={useHoverColor}
                            primaryText={usePrimaryText}
                            highlightText={useHighlightText}
                            buttonHighlight={buttonHighlight}
                            iconColor={iconFill}
                            iconHighlight={iconHighlight}
                          />
                        ))}
                      </Box>
                      {cyclopsEnabled && <StyledHr color={useNavBorder} />}
                    </>
                  )}
                  {cyclopsEnabled && (
                    <>
                      <MenuSectionHeader label="Cyclops" textColor={usePrimaryText} collapsed={navCollapsed} />
                      <Box direction="column" gap="0.5rem">
                        {!isCyclopsDemo && (
                          <animated.div style={{ opacity, display }}>
                            <Box pad={{ top: '0.35rem' }}>
                              <MenuDropdownSelect
                                small={small}
                                handleSelect={(val) => setActiveOrganization(val)}
                                options={(
                                  companyOrganizations?.map(({ Id, name }) => ({ uid: Id, name }))
                                )}
                                title="Organization"
                                titleColor={(
                                  darkMode ? darkModeColors.secondaryText : usePrimaryText
                                )}
                                selectLabel={organizationsActive ? activeOrganization.name : 'Select organization'}
                                buttonLabel="Create an organization"
                                buttonLabelColor={(darkMode
                                  ? darkModeColors.primaryText
                                  : highlightText
                                )}
                                buttonColor={darkMode ? '#454A59' : hoverColor}
                                buttonPath={paths.cyclopsOrganizations}
                                secondaryButtonLabel="View organizations"
                                selectMenuBg={darkMode ? '#454A59' : 'white'}
                                selectButtonBg={darkMode ? '#454A59' : 'white'}
                                selectButtonPrimary={useSelectButtonPrimary}
                                selectButtonHighlight={useSelectButtonHighlight}
                                selectHoverBg={useSelectHoverBg}
                              />
                            </Box>
                          </animated.div>
                        )}
                        {!cyclopsAnalyticsDisabled && (
                          <MenuItemRenderer
                            first
                            key="cyclops-analytics"
                            location={location}
                            renderKey="analytics"
                            label="Analytics"
                            icons={navIcons}
                            path={paths.cyclopsAnalytics}
                            collapsed={navCollapsed}
                            hoverColor={useHoverColor}
                            primaryText={usePrimaryText}
                            highlightText={useHighlightText}
                            buttonHighlight={buttonHighlight}
                            iconColor={iconFill}
                            iconHighlight={iconHighlight}
                          />
                        )}
                        <MenuItemRenderer
                          key="cyclops-live-events"
                          first={cyclopsAnalyticsDisabled}
                          disabled={cyclopsMenuButtonsDisabled}
                          location={location}
                          renderKey="live-events"
                          label="Live events"
                          icons={navIcons}
                          path={paths.cyclopsEventsList.replace(':status', 'live')}
                          collapsed={navCollapsed}
                          hoverColor={useHoverColor}
                          primaryText={usePrimaryText}
                          highlightText={useHighlightText}
                          buttonHighlight={buttonHighlight}
                          iconColor={iconFill}
                          iconHighlight={iconHighlight}
                        />
                        <MenuItemRenderer
                          key="cyclops-past-events"
                          disabled={cyclopsMenuButtonsDisabled}
                          location={location}
                          renderKey="past-events"
                          label="Past events"
                          icons={navIcons}
                          path={paths.cyclopsEventsList.replace(':status', 'past')}
                          collapsed={navCollapsed}
                          hoverColor={useHoverColor}
                          primaryText={usePrimaryText}
                          highlightText={useHighlightText}
                          buttonHighlight={buttonHighlight}
                          iconColor={iconFill}
                          iconHighlight={iconHighlight}
                        />
                        {!cyclopsSettingsDisabled && (
                          <MenuItemRenderer
                            key="cyclops-settings"
                            disabled={cyclopsMenuButtonsDisabled}
                            location={location}
                            renderKey="settings"
                            label="Settings"
                            icons={navIcons}
                            path={paths.cyclopsSettings}
                            collapsed={navCollapsed}
                            hoverColor={useHoverColor}
                            primaryText={usePrimaryText}
                            highlightText={useHighlightText}
                            buttonHighlight={buttonHighlight}
                            iconColor={iconFill}
                            iconHighlight={iconHighlight}
                          />
                        )}
                      </Box>
                    </>
                  )}
                </Box>
                <Box justify={navCollapsed ? 'center' : 'end'} align={navCollapsed ? 'center' : 'end'} pad={{ right: navCollapsed ? '0' : '0.5rem' }}>
                  <Button plain onClick={() => setNavCollapsed(!navCollapsed)}>
                    <animated.div style={{ transform, rotate: '180deg' }}>
                      <FormNext size="2.65rem" color={buttonHighlight} />
                    </animated.div>
                  </Button>
                </Box>
              </Box>
            </StyledMenu>
          </Sidebar>
        )}
        <Container direction="column" height={{ min: '100vh' }}>
          {!hideBanner && bannerProps && (
            <AuthBanner
              small={false}
              smallBanner={smallBanner}
              adjustLeftPad={hideSideNav}
              image={bannerBg}
              textColor={bannerProps.textColor}
              title={bannerProps.title}
              subTitle={bannerProps.subTitle}
              bannerChildren={bannerProps.bannerChildren}
              bannerChildrenPosition={bannerProps.bannerChildrenPosition}
              navCollapsed={navCollapsed}
            />
          )}
          <Box flex pad={!hideSideNav && { left: navCollapsed ? NAV_COLLAPSED_WIDTH : NAV_WIDTH }} style={{ transition: 'all 0.35s ease' }}>
            {children}
          </Box>
          {!hideFooter && (
            <AuthFooter
              small={small}
              mixpanel={mixpanel}
              config={footerConfig}
              authPagesConfig={authPagesConfig}
              darkMode={darkMode}
            />
          )}
        </Container>
      </Box>
    </FullHeightBox>
  );
};

function mapStateToProps(state) {
  const { companyId, companyFeatures, companyName } = state.auth;
  const { companyOrganizations, activeCompanyOrganization: activeOrganization } = state.cyclops;

  return {
    companyId,
    companyFeatures,
    companyName,
    companyOrganizations,
    activeOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCompanyOrganizations: initiateCyclopsCompanyOrganizationsRequest,
    setActiveOrganization: setCyclopsActiveOrganizationRequest,
  }, dispatch);
}

AuthNavWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    sideNavBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    defaultIconColor: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    footerConfig: PropTypes.shape({
      lowerBgColor: PropTypes.string.isRequired,
      lowerTextColor: PropTypes.string.isRequired,
      secondaryLowerTextColor: PropTypes.string.isRequired,
    }).isRequired,
    navIcons: PropTypes.shape({
      piracyFlagIcon: PropTypes.string.isRequired,
      sentimentHeartIcon: PropTypes.string.isRequired,
      liveSearchesIcon: PropTypes.string.isRequired,
      pausedSearchesIcon: PropTypes.string.isRequired,
      completedSearchesIcon: PropTypes.string.isRequired,
      allSearchesIcon: PropTypes.string.isRequired,
      customReportsIcon: PropTypes.string.isRequired,
      settingsIcon: PropTypes.string.isRequired,
      cyclopsAnalyticsIcon: PropTypes.string.isRequired,
      cyclopsLiveEventsIcon: PropTypes.string.isRequired,
      cyclopsPastEventsIcon: PropTypes.string.isRequired,
    }).isRequired,
    listMenuMap: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    hideBlacklightSearchCompanies: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    showStatusMessage: PropTypes.bool,
    statusMessageConfig: PropTypes.shape({
      message: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      statusDotColor: PropTypes.string.isRequired,
    }),
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
      navBorder: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  bannerProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    bannerChildren: PropTypes.node,
    bannerChildrenPosition: PropTypes.oneOf(['bottom', 'right']),
  }),
  companyFeatures: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    config: PropTypes.objectOf(PropTypes.any),
  }).isRequired).isRequired,
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  setActiveOrganization: PropTypes.func.isRequired,
  fetchCompanyOrganizations: PropTypes.func.isRequired,
  companyOrganizations: PropTypes.arrayOf(PropTypes.any),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  darkMode: PropTypes.bool,
  smallBanner: PropTypes.bool,
  hideBanner: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavWrapper);
