import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  Routes, Route, useNavigate, useLocation, useNavigationType,
} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { Navigation } from '@Components/Navigation';
import {
  LoginPage,
  ForgotPasswordPage,
  RecoverAccountPage,
  ProductNewSearchPage,
  ProductDashboardResultsPage,
  ProductDashboardFlexibleResultsPage,
  ProductDashboardReviewPage,
  ProductListSearchesPage,
  ProductFlexibleListSearchesPage,
  ProductPiracyOverviewPage,
  ProductCustomReportsListPage,
  ProductAccountPage,
  CyclopsAnalyticsPage,
  CyclopsEventsListPage,
  CyclopsEventDetailsPage,
  CyclopsSettingsPage,
  CyclopsOrganizationsPage,
  Error404Page,
} from '@Components/Page';

import { paths } from '@Components/configs';
import { clearRedirectRequest } from '@Actions';
import { setFaviconStatus } from '@Helpers/util';
import { ProductAuthWrapper, NoAuthWrapper } from './components/Layout/AuthRouteWrappers';

mixpanel.init(process.env.MIXPANEL_TOKEN, { track_pageview: false });

const AppRoutes = ({
  size,
  isRedirectRequired,
  faviconStatus,
  clearRedirect,
  redirectPush,
  redirectPath = null,
  isAuthenticated,
  companyFeatures = null,
  companyId = null,
  companyName = null,
  authPagesConfig = null,
  embedReportsConfig = null,
  cyclopsConfig = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();

  const flexibleData = companyFeatures && _.find(companyFeatures, { key: 'flexible_company_data' });
  const flexibleDataCompany = flexibleData?.enabled;
  const overviewData = companyFeatures && _.find(companyFeatures, { key: 'overview_embed' });
  const overviewEnabled = overviewData?.enabled && overviewData?.config?.embedId !== null;
  const searchData = companyFeatures && _.find(companyFeatures, { key: 'search' });
  const searchEnabled = searchData?.enabled;
  const companyReview = companyFeatures && _.find(companyFeatures, { key: 'review' });
  const reviewEnabled = companyReview?.enabled;
  const companyReports = embedReportsConfig && embedReportsConfig.find(({ metadata }) => (
    metadata?.config?.companyId === companyId && metadata?.config?.companyName === companyName));
  const withReports = companyReports?.metadata?.config?.customReports?.length > 0;
  const cyclopsData = companyFeatures && _.find(companyFeatures, { key: 'cyclops' });
  const isCyclopsDemo = cyclopsData?.config?.demoOnly;
  const companyCyclopsDemoData = cyclopsData && cyclopsConfig?.find(({ metadata }) => (
    metadata?.config?.companyId === companyId && metadata?.config?.companyName === companyName));
  const cyclopsEnabled = isCyclopsDemo
    ? (cyclopsData?.enabled && companyCyclopsDemoData !== undefined)
    : cyclopsData?.enabled;
  const cyclopsAnalyticsDisabled = isCyclopsDemo && cyclopsData?.config?.disableAnalytics;
  const cyclopsSettingsDisabled = isCyclopsDemo && cyclopsData?.config?.disableSettings;

  const unconfiguredCompany = !flexibleDataCompany && !reviewEnabled && !searchEnabled
    && !overviewEnabled && !withReports && !cyclopsEnabled;

  React.useEffect(() => {
    if (isAuthenticated && flexibleDataCompany && (!location.pathname.includes('dynamic-searches') && location.pathname !== paths.account)) {
      navigate(paths.productFlexibleSearches);
    }

    if (isAuthenticated && location.pathname === paths.productPiracyOverview && !overviewEnabled) {
      navigate(paths.root);
    }

    if (isAuthenticated && !overviewEnabled && searchEnabled && (location.pathname === paths.root
      || location.pathname === paths.login
    )) {
      navigate(paths.productListSearches.replace(':status', 'all'));
    }

    if (isAuthenticated && !overviewEnabled && !searchEnabled && reviewEnabled
      && (location.pathname === paths.root || location.pathname === paths.login)) {
      navigate(paths.productReview);
    }

    if (isAuthenticated && overviewEnabled && (location.pathname === paths.root
      || location.pathname === paths.login
    )) {
      navigate(paths.productPiracyOverview);
    }

    if (isAuthenticated && !cyclopsEnabled && location.pathname.includes('cyclops')) {
      navigate(paths.root);
    }

    if (isAuthenticated && cyclopsEnabled && (cyclopsAnalyticsDisabled || !isCyclopsDemo)
      && location.pathname === paths.cyclopsAnalytics
    ) {
      navigate(paths.root);
    }

    if (isAuthenticated && cyclopsEnabled && cyclopsSettingsDisabled
      && location.pathname === paths.cyclopsSettings
    ) {
      navigate(paths.root);
    }

    if (isAuthenticated && cyclopsEnabled && isCyclopsDemo && !cyclopsAnalyticsDisabled && (
      location.pathname === paths.root || location.pathname === paths.login
    ) && !location.pathname.includes('cyclops')) {
      navigate(paths.cyclopsAnalytics);
    }

    if (isAuthenticated && cyclopsEnabled && cyclopsAnalyticsDisabled && isCyclopsDemo && (
      location.pathname === paths.root || location.pathname === paths.login
    ) && !location.pathname.includes('cyclops')) {
      navigate(paths.cyclopsEventsList.replace(':status', 'live'));
    }

    if (isAuthenticated && cyclopsEnabled && !isCyclopsDemo && (
      location.pathname === paths.root || location.pathname === paths.login
    ) && !location.pathname.includes('cyclops')) {
      navigate(paths.cyclopsOrganizations);
    }

    if (!reviewEnabled && location.pathname === paths.productReview) {
      navigate(paths.root);
    }

    if (!isAuthenticated && location.pathname === paths.root) {
      navigate(paths.login);
    }

    if (isAuthenticated && unconfiguredCompany && location.pathname !== paths.account) {
      navigate(paths.account);
    }
  }, [isAuthenticated, location.pathname]);

  React.useEffect(() => {
    if (!isRedirectRequired) {
      setFaviconStatus(faviconStatus);
    }

    if (isRedirectRequired && redirectPath) {
      navigate(redirectPath, { replace: redirectPush, state: { refresh: redirectPush } });
      window.scrollTo(0, 0);
      clearRedirect();
    }
  }, [isRedirectRequired, faviconStatus]);

  const small = size.includes('small');
  const authProps = { location, navType };

  return (
    <Routes>
      <Route
        element={(
          <Navigation
            small={small}
            authPageActive={false}
            mixpanel={mixpanel}
            authPagesConfig={authPagesConfig}
            {...authProps}
          />
        )}
      >
        <Route
          index
          element={(
            <NoAuthWrapper isAuthenticated={isAuthenticated}>
              <LoginPage
                small={small}
                mixpanel={mixpanel}
                config={authPagesConfig.authenticationConfig}
              />
            </NoAuthWrapper>
          )}
        />
        <Route
          path={paths.login}
          element={(
            <NoAuthWrapper isAuthenticated={isAuthenticated}>
              <LoginPage
                small={small}
                mixpanel={mixpanel}
                config={authPagesConfig.authenticationConfig}
              />
            </NoAuthWrapper>
          )}
        />
        <Route
          path={paths.forgotPassword}
          element={(
            <NoAuthWrapper isAuthenticated={isAuthenticated}>
              <ForgotPasswordPage
                small={small}
                mixpanel={mixpanel}
                config={authPagesConfig.authenticationConfig}
              />
            </NoAuthWrapper>
          )}
        />
        <Route
          path={paths.recoverAccount}
          element={(
            <NoAuthWrapper isAuthenticated={isAuthenticated}>
              <RecoverAccountPage
                small={small}
                mixpanel={mixpanel}
                config={authPagesConfig.authenticationConfig}
              />
            </NoAuthWrapper>
          )}
        />
        <Route
          path="*"
          element={(
            <Error404Page mixpanel={mixpanel} />
          )}
        />
      </Route>
      <Route
        element={(
          <Navigation
            authPageActive
            small={small}
            mixpanel={mixpanel}
            authPagesConfig={authPagesConfig}
            flexibleDataCompany={flexibleDataCompany}
            reportsConfig={embedReportsConfig}
            searchEnabled={searchEnabled}
            cyclopsEnabled={cyclopsEnabled}
            {...authProps}
          />
        )}
      >
        <Route
          path={paths.productNewSearch}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductNewSearchPage
                small={small}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productSearchResults}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductDashboardResultsPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productFlexibleSearchResults}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductDashboardFlexibleResultsPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productReview}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductDashboardReviewPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productListSearches}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductListSearchesPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productFlexibleSearches}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductFlexibleListSearchesPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productPiracyOverview}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductPiracyOverviewPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                embedConfig={overviewData?.config}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productCustomReports}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductCustomReportsListPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.productCustomReport}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductPiracyOverviewPage
                isReport
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.account}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <ProductAccountPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                flexibleDataCompany={flexibleDataCompany}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                unconfiguredCompany={unconfiguredCompany}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path="*"
          element={(
            <Error404Page mixpanel={mixpanel} />
          )}
        />
      </Route>
      <Route
        element={(
          <Navigation
            authPageActive
            darkMode
            small={small}
            mixpanel={mixpanel}
            authPagesConfig={authPagesConfig}
            flexibleDataCompany={flexibleDataCompany}
            reportsConfig={embedReportsConfig}
            searchEnabled={searchEnabled}
            cyclopsEnabled={cyclopsEnabled}
            {...authProps}
          />
        )}
      >
        <Route
          path={paths.cyclopsAnalytics}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <CyclopsAnalyticsPage
                small={small}
                demoOnly={isCyclopsDemo}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                companyDemoData={companyCyclopsDemoData}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.cyclopsOrganizations}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <CyclopsOrganizationsPage
                small={small}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.cyclopsEventsList}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <CyclopsEventsListPage
                small={small}
                demoOnly={isCyclopsDemo}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                companyDemoData={companyCyclopsDemoData}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.cyclopsEventDetails}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <CyclopsEventDetailsPage
                small={small}
                demoOnly={isCyclopsDemo}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                companyDemoData={companyCyclopsDemoData}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path={paths.cyclopsSettings}
          element={(
            <ProductAuthWrapper isAuthenticated={isAuthenticated}>
              <CyclopsSettingsPage
                small={small}
                demoOnly={isCyclopsDemo}
                size={size}
                mixpanel={mixpanel}
                authPagesConfig={authPagesConfig}
                reportsConfig={embedReportsConfig}
                cyclopsConfig={cyclopsConfig}
                companyDemoData={companyCyclopsDemoData}
                {...authProps}
              />
            </ProductAuthWrapper>
          )}
        />
        <Route
          path="*"
          element={(
            <Error404Page mixpanel={mixpanel} />
          )}
        />
      </Route>
    </Routes>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    companyFeatures: state.auth.companyFeatures,
    companyId: state.auth.companyId,
    companyName: state.auth.companyName,
    isRedirectRequired: state.redirect.isRequired,
    redirectPath: state.redirect.path,
    redirectPush: state.redirect.push,
    faviconStatus: state.favicon.faviconStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clearRedirect: clearRedirectRequest,
  }, dispatch);
}

AppRoutes.propTypes = {
  size: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isRedirectRequired: PropTypes.bool.isRequired,
  redirectPath: PropTypes.objectOf(PropTypes.any),
  redirectPush: PropTypes.bool.isRequired,
  faviconStatus: PropTypes.string.isRequired,
  clearRedirect: PropTypes.func.isRequired,
  companyFeatures: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired),
  companyId: PropTypes.number,
  companyName: PropTypes.string,
  authPagesConfig: PropTypes.objectOf(PropTypes.any),
  embedReportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
