import {
  FETCH_LOADER_LOADING,
  FETCH_LOADER_FINISHED,
  SUBMISSION_LOADER_LOADING,
  SUBMISSION_LOADER_FINISHED,
} from './actionTypes';

// Reducer corresponding actions
function fetchLoaderReset() {
  return { type: FETCH_LOADER_FINISHED };
}

function fetchLoaderLoading() {
  return { type: FETCH_LOADER_LOADING };
}

function submissionLoaderReset() {
  return { type: SUBMISSION_LOADER_FINISHED };
}

function submissionLoaderLoading() {
  return { type: SUBMISSION_LOADER_LOADING };
}

// Private: only to be dispatched within actions
// for use in GET requests for main page data only
export function setFetchLoaderReset() {
  return (dispatch) => {
    dispatch(fetchLoaderReset());
  };
}

export function setFetchLoaderLoading() {
  return (dispatch) => {
    dispatch(fetchLoaderLoading());
  };
}

// For use in POST, DEL, or PATCH requests for longer form submissions
export function setSubmissionLoaderReset() {
  return (dispatch) => {
    dispatch(submissionLoaderReset());
  };
}

export function setSubmissionLoaderLoading() {
  return (dispatch) => {
    dispatch(submissionLoaderLoading());
  };
}
