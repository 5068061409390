import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text,
} from 'grommet';

import {
  FilesTable, SelectionCard, FeatureContainer,
} from '../CyclopsComponents';
import {
  StyledCyclopsContainer, StyledHr,
} from '../StyledCyclopsComponents';


const SettingsDemoContent = ({
  small, authPagesConfig = null, companyDemoData = null,
}) => {
  const {
    darkModeColors: {
      containerBg, primaryText, secondaryText,
    },
  } = authPagesConfig;
  let settingsData = null;
  let featuresData = null;
  let contentOverrideData = null;
  let eventSchedulesData = null;
  let detectionSitesData = null;

  if (!companyDemoData) return null;

  if (companyDemoData) {
    settingsData = companyDemoData.metadata.config.settingsData;
    featuresData = settingsData.features;

    contentOverrideData = featuresData.find(({ key }) => key === 'contentOverride');
    eventSchedulesData = featuresData.find(({ key }) => key === 'eventSchedules');
    detectionSitesData = featuresData.find(({ key }) => key === 'detectionSites');
  }

  return (
    <Box direction="column" gap="1.5rem">
      <StyledCyclopsContainer background={containerBg} direction="column" gap="0.5rem" width={small ? '75%' : '45%'}>
        <Box direction="row" justify="between" align="center">
          <Text size="1.1rem" weight={600} color={primaryText}>CDNs:</Text>
          <Text size="1.1rem" weight={600} color={primaryText}>{settingsData.activeCDN}</Text>
        </Box>
        <StyledHr color="#D0D0D0" />
        <Box direction="row" justify="between" align="center">
          <Text size="1.1rem" weight={600} color={primaryText}>Encoder:</Text>
          <Text size="1.1rem" weight={600} color={primaryText}>{settingsData.activeEncoder}</Text>
        </Box>
      </StyledCyclopsContainer>
      <Box direction="column" gap="1rem">
        <Text size="1rem" color={secondaryText} weight={500}>
          Features
        </Text>
        <Box direction="column" gap="2rem">
          {detectionSitesData && (
            <FeatureContainer
              key={uniqid()}
              small={small}
              title="Detection"
              description="Enable monitoring of known piracy sites. Detection activates after uploading at least one CSV file."
              primaryText={primaryText}
              containerBg={containerBg}
              toggleValue={detectionSitesData.files.length > 0}
            >
              <FilesTable
                small={small}
                textColor={primaryText}
                headerColor={secondaryText}
                filesData={detectionSitesData.files}
                handleUpload={() => null}
              />
            </FeatureContainer>
          )}
          {eventSchedulesData && (
            <FeatureContainer
              key={uniqid()}
              small={small}
              title="Livestream Shutdown and Event Schedule"
              description="Enable Livestream Shutdown by uploading a schedule of your upcoming events. This allows us to monitor and shut down unauthorized streams in real time."
              primaryText={primaryText}
              containerBg={containerBg}
              toggleValue={eventSchedulesData.files.length > 0}
            >
              <FilesTable
                small={small}
                textColor={primaryText}
                headerColor={secondaryText}
                filesData={eventSchedulesData.files}
                handleUpload={() => null}
              />
            </FeatureContainer>
          )}
          {contentOverrideData && (
            <FeatureContainer
              key={uniqid()}
              small={small}
              title="Content Override"
              description="Replace piracy streams with custom content."
              primaryText={primaryText}
              containerBg={containerBg}
            >
              <Box wrap direction="row" gap="1rem">
                {contentOverrideData.files.map(({
                  fileName, fileUid, description, fileUrl, selected, removable,
                }) => (
                  <Box basis={small ? 'full' : '1/3'} pad={{ vertical: '1rem', horizontal: small ? '0' : '1rem' }} margin={{ left: small ? '0' : '-1rem' }} key={fileUid}>
                    <SelectionCard
                      small={small}
                      title={fileName}
                      description={description}
                      fileUid={fileUid}
                      primaryText={primaryText}
                      highlightColor={secondaryText}
                      containerBg={containerBg}
                      imageUrl={fileUrl}
                      selected={selected}
                      removable={removable}
                      handleSelect={() => null}
                      handleRemove={() => null}
                    />
                  </Box>
                ))}
              </Box>
            </FeatureContainer>
          )}
        </Box>
      </Box>
    </Box>
  );
};

SettingsDemoContent.propTypes = {
  small: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  companyDemoData: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        companyName: PropTypes.string.isRequired,
        settingsData: PropTypes.shape({
          activeCDN: PropTypes.string.isRequired,
          activeEncoder: PropTypes.string.isRequired,
          features: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            files: PropTypes.arrayOf(PropTypes.shape({
              fileName: PropTypes.string.isRequired,
              fileUid: PropTypes.number.isRequired,
              description: PropTypes.string,
              fileUrl: PropTypes.string.isRequired,
              fileSize: PropTypes.string,
              uploadDate: PropTypes.string,
              selected: PropTypes.bool,
              removable: PropTypes.bool,
            })).isRequired,
          }).isRequired).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export default SettingsDemoContent;
