import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Text, Heading,
} from 'grommet';
import { FormClose } from 'grommet-icons';

import {
  StyledFormInput,
  StyledFormInputClear,
  StyledRaisedContainer,
  StyledSmallButton,
} from './StyledProductFormComponent';


export const FormSectionWrapper = ({
  small, sectionBg, sectionTitle, titleColor, borderColor, children,
}) => (
  <Box
    width={{ min: small ? '100%' : '50%' }}
    height="fit-content"
    direction="column"
    round="0.35rem"
    pad={{ top: '1.5rem', bottom: '1rem', horizontal: small ? '0.5rem' : '1rem' }}
    background={sectionBg}
    border={{
      color: borderColor,
      size: '1px',
      style: 'solid',
      side: 'all',
    }}
  >
    <Box justify="center" align="center">
      <Heading level={4} margin="none" size="1rem" color={titleColor} textAlign="center">
        {sectionTitle}
      </Heading>
    </Box>
    <Box>
      {children}
    </Box>
  </Box>
);

FormSectionWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  sectionBg: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const FormFieldContainer = ({
  small,
  fieldTitle,
  description = null,
  limit = null,
  charLimit = null,
  primaryText,
  hintText,
  borderColor,
  children,
}) => {
  let displayDescription = description;

  if (limit) displayDescription = displayDescription.replace('{{limit}}', limit);
  if (charLimit) displayDescription = displayDescription.replace('{{char_limit}}', charLimit);

  return (
    <StyledRaisedContainer
      direction="column"
      gap="0.5rem"
      pad={{ horizontal: '1.5rem', vertical: '1rem' }}
      borderColor={borderColor}
      margin={{ top: '1rem' }}
    >
      <Box direction="column" gap="0.25rem">
        <Text weight={600} size="1rem" color={primaryText}>
          {fieldTitle}
        </Text>
        {displayDescription && (
          <Text size={small ? '0.85rem' : '0.75rem'} color={hintText}>
            {displayDescription}
          </Text>
        )}
      </Box>
      <Box>
        {children}
      </Box>
    </StyledRaisedContainer>
  );
};

FormFieldContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  limit: PropTypes.number,
  charLimit: PropTypes.number,
  primaryText: PropTypes.string.isRequired,
  hintText: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const FormInput = React.forwardRef(({
  onClear = null, clearActive = false, ...rest
}, ref) => {
  if (onClear) {
    return (
      <Box className="relative">
        <StyledFormInput
          plain
          ref={ref}
          padRight={clearActive ? '1.9rem' : null}
          {...rest}
        />
        <StyledFormInputClear
          plain
          icon={<FormClose size="1.25rem" color="#636363" />}
          onClick={onClear}
          style={clearActive ? null : { display: 'none' }}
        />
      </Box>
    );
  }

  return <StyledFormInput plain ref={ref} {...rest} />;
});

FormInput.propTypes = {
  onClear: PropTypes.func,
  clearActive: PropTypes.bool,
};

export const SingleTextInput = ({
  focusHighlight,
  value = '',
  handleFormValues,
  ...rest
}) => (
  <FormInput
    value={value}
    onChange={(e) => handleFormValues(e.target.value)}
    highlight={focusHighlight}
    {...rest}
  />
);

SingleTextInput.propTypes = {
  focusHighlight: PropTypes.string.isRequired,
  handleFormValues: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const AddButton = ({
  disabled = false, highlight, ...rest
}) => {
  const buttonLabelColor = disabled ? '#A2A2A2' : highlight;

  return (
    <StyledSmallButton plain alignSelf="start" disabled={disabled} highlight={highlight} {...rest}>
      <Box
        direction="row"
        round="5px"
        align="center"
        background={disabled ? 'var(--light-grey)' : 'white'}
        border={{ size: '1px', color: '#D0D0D0', style: 'solid' }}
        pad={{ vertical: '0.35rem', horizontal: '0.85rem' }}
        gap="0.4rem"
      >
        <Text weight={600} textAlign="center" size="0.8rem" color={buttonLabelColor}>
          + Add
        </Text>
      </Box>
    </StyledSmallButton>
  );
};

AddButton.propTypes = {
  disabled: PropTypes.bool,
  highlight: PropTypes.string.isRequired,
};

export const TagButton = ({
  tag, highlight = '#174EA6', textColor, ...rest
}) => (
  <StyledSmallButton
    plain
    alignSelf="start"
    margin="0.125rem"
    highlight={highlight}
    {...rest}
  >
    <Box
      direction="row"
      round="0.25rem"
      align="center"
      background="white"
      border={{ size: '1px', color: highlight, style: 'solid' }}
      pad={{ vertical: '0.25rem', right: '0.5rem', left: '0.75rem' }}
      gap="0.75rem"
    >
      <Box>
        <Text textAlign="center" size="1rem" color={textColor}>
          {tag.label}
        </Text>
      </Box>
      <FormClose size="1.25rem" color="#636363" />
    </Box>
  </StyledSmallButton>
);

TagButton.propTypes = {
  tag: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  highlight: PropTypes.string,
  textColor: PropTypes.string.isRequired,
};
