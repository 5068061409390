import React from 'react';
import styled from 'styled-components';

import { Box, Heading } from 'grommet';
import { ReactSVG } from 'react-svg';
import { AppAnchor } from '@Components/Control';
import { Menu } from 'react-pro-sidebar';

const StyledMenuItem = styled((props) => <AppAnchor {...props} />)`
  transition: 'all 0.2s ease';
  border-radius: 4px;
  margin-top: ${({ first }) => first && '0.35rem'};

  &:hover:not(:disabled) {
    background-color: ${({ buttonHoverColor }) => buttonHoverColor};

    span {
      color: ${({ highlightText }) => highlightText};
    }

    .highlightIcon {
      svg {
        fill: ${({ iconHighlight }) => iconHighlight};

        path {
          fill: ${({ iconHighlight }) => iconHighlight};
        }
      }
    }
  }

  ${({ active, isDisabled }) => ((active && !isDisabled) ? `
    background-color: ${({ buttonHoverColor }) => buttonHoverColor};

    span {
      color: ${({ highlightText }) => highlightText};
    }
  ` : `
    span {
      color: ${({ primaryText, disabledText }) => (isDisabled ? disabledText : primaryText)};
    }
  `)}

  .sideNavTip {
    font-family: inherit;
    border-radius: 4px;
    background-color: ${({ buttonHighlight }) => buttonHighlight};
  }
`;

const StyledSVG = styled(ReactSVG)`
  svg {
    height: ${({ height }) => height};
    width:${({ width }) => width};
    fill: ${({ $fillColor }) => $fillColor};

    path {
      fill: ${({ $fillColor }) => $fillColor};
    }
  }
`;

const StyledMenu = styled(Menu)`
  height: 99%;
  transition: 'all 0.2s ease';

  ul { height: 99% };
`;

const StyledHr = styled.hr`
  color: ${({ color }) => color};
  transition: 'width 0.2s ease';
  width: 100%;
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin-block-start: 1rem;
`;

const StyledAuthBanner = styled(Box)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.35s ease;
`;

const StyledBannerHeader = styled(Heading)`
  font-weight: ${({ weight }) => weight};
`;

const StyledStickyFooter = styled(Box)`
  position: sticky;
  bottom: 0;
  z-index: 10;
`;

export {
  StyledMenuItem,
  StyledMenu,
  StyledHr,
  StyledAuthBanner,
  StyledBannerHeader,
  StyledSVG,
  StyledStickyFooter,
};
