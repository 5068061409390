import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import rootReducer from '@Reducers';

const persistConfig = {
  key: 'root',
  version: 23,
  storage,
  whitelist: ['auth', 'cyclops'],
};

const stateSyncConfig = {
  channel: 'red-flag-frontend',
  whitelist: ['AUTH_LOG_IN', 'AUTH_LOG_OUT', 'AUTH_REFRESH', 'CYCLOPS_ACTIVE_ORGANIZATION_SET', 'CYCLOPS_ACTIVE_ORGANIZATION_CLEAR'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (initialState) => {
  if (process.env.NODE_ENV === 'development') {
    return createStore(
      persistedReducer,
      initialState,
      compose(applyMiddleware(
        reduxImmutableStateInvariant(),
        thunkMiddleware,
        createLogger(),
        createStateSyncMiddleware(stateSyncConfig),
      )),
    );
  }
  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(
      thunkMiddleware,
      createStateSyncMiddleware(stateSyncConfig),
    )),
  );
};

const store = configureStore();
initStateWithPrevTab(store);

export default store;
