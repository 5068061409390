import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { StyledCheckBox } from './StyledProductFormComponent';


const CheckBoxInput = ({
  borderColor,
  focusHighlight,
  checkmarkColor,
  handleFormValues,
  value = false,
  label = null,
}) => {
  const [checked, setChecked] = React.useState(value);

  const handleSelection = (e) => {
    setChecked(e.target.checked);
    handleFormValues(e.target.checked);
  };

  return (
    <Box width="100%">
      <StyledCheckBox
        checked={checked}
        label={label}
        onChange={(event) => handleSelection(event)}
        borderColor={borderColor}
        focusHighlight={focusHighlight}
        color={checkmarkColor}
      />
    </Box>
  );
};

CheckBoxInput.propTypes = {
  borderColor: PropTypes.string.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  checkmarkColor: PropTypes.string.isRequired,
  handleFormValues: PropTypes.func.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string,
};

export default CheckBoxInput;
