import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import _ from 'lodash';
import {
  format, subDays, addDays,
} from 'date-fns';
import { UTCDate } from '@date-fns/utc';

import {
  Box, Text, Image, Layer, Button,
} from 'grommet';
import {
  Next, Previous, FormClose,
} from 'grommet-icons';

import { Spinning, AppButton } from '@Components/Control';
import { DateRangeCalendar } from '@Components/Control/Calendar';
import { CurrentDateContext } from '@Components/Context';
import { ProductFormSingleTextInput } from '@Components/Partial/Product/NewSearch';
import { localAsset } from '@Helpers/asset';
import { shortenInt } from '@Helpers/util';
import {
  StyledText,
  StyledRaisedContainer,
  StyledSimpleDrop,
  StyledFlexibleDrop,
  StyledSelectMenu,
  StyledPaginationArrow,
  StyledSVG,
  StyledTextInput,
  StyledSearchInputClear,
  StyledRangeSelect,
} from './StyledSharedComponents';
import {
  StyledModalSelectButton,
  StyledFormCloseContainer,
} from './NewSearch/StyledProductFormComponent';


export const renderTableHeader = (header, small, color = '#84919A') => {
  const mediumScreen = window.innerWidth <= 950;

  return (
    <StyledText
      uppercase
      size={(small || mediumScreen) ? '13px' : '15px'}
      color={color}
      weight={600}
      wordBreak="keep-all"
      style={{ letterSpacing: '0.216px' }}
    >
      {header}
    </StyledText>
  );
};

export const NoDataPlaceholder = ({
  loading,
  loaderColor,
  textColor,
  label,
  boxMargin = '0',
  noShadow = false,
  noRound = false,
  darkmode = false,
  backgroundColor = 'white',
  showRefreshButton = false,
  refreshQuery = () => null,
  buttonHighlight = null,
}) => {
  const renderContent = () => {
    const imagePath = darkmode ? 'images/logo/logo-full-white.svg' : 'images/logo/logo-full-color.svg';

    if (!loading) {
      return (
        <>
          <Box flex={false} width="10.65rem" height="3rem">
            <Image
              src={localAsset(imagePath)}
              alt="Redflag AI Logo"
              fit="contain"
              width="10.65rem"
              height="3rem"
            />
          </Box>
          <Box width="100%" align="center" pad={{ top: '1rem' }}>
            <Text color={textColor} size="1rem">{label}</Text>
          </Box>
          {showRefreshButton && (
            <Box width="100%" pad={{ top: '1rem' }} align="center">
              <AppButton
                overrideHover
                width="7.25rem"
                height="3rem"
                onClick={() => refreshQuery()}
                level="dynamicLarge"
                color="white"
                bgColor={buttonHighlight}
                fontWeight={600}
                label="Reset"
              />
            </Box>
          )}
        </>
      );
    }

    return (
      <Spinning size="large" color={loaderColor} />
    );
  };

  return (
    <StyledRaisedContainer
      background={backgroundColor}
      height="18rem"
      justify="center"
      align="center"
      gap="1rem"
      margin={boxMargin}
      noShadow={noShadow}
      noRound={noRound}
    >
      {renderContent()}
    </StyledRaisedContainer>
  );
};

NoDataPlaceholder.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaderColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  boxMargin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any.isRequired),
  ]),
  noShadow: PropTypes.bool,
  noRound: PropTypes.bool,
  darkmode: PropTypes.bool,
  backgroundColor: PropTypes.string,
  buttonHighlight: PropTypes.string,
  showRefreshButton: PropTypes.bool,
  refreshQuery: PropTypes.func,
};

const SelectionDropdown = ({
  textColor,
  handleSelection,
  options,
  label,
  iconPath,
}) => (
  <StyledSimpleDrop
    formIcons
    larger
    withCloseDropContent={(onClose) => (
      <Box round="0.35rem" pad="12px">
        {options.map(({ name, formatted }) => (
          <StyledSelectMenu
            plain
            key={uniqid()}
            label={formatted}
            onClick={() => {
              handleSelection(name);
              onClose();
            }}
            hoverIndicator="#F0F6FF"
            highlightText="#004FFE"
          />
        ))}
        <StyledSelectMenu
          plain
          key="reset"
          label="Reset"
          onClick={() => {
            handleSelection(null);
            onClose();
          }}
          hoverIndicator="#F0F6FF"
          highlightText="#004FFE"
        />
      </Box>
    )}
  >
    {() => (
      <AppButton
        fill
        dropDown
        overrideReverse
        textSize="1rem"
        color={textColor}
        label={label}
        icon={(
          <StyledSVG
            src={localAsset(iconPath)}
            width="1.1rem"
            height="1.1rem"
            title={label}
          />
        )}
      />
    )}
  </StyledSimpleDrop>
);

SelectionDropdown.propTypes = {
  textColor: PropTypes.string.isRequired,
  handleSelection: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  label: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
};

function createInitialState(initialValue) {
  let newValue = '';
  if (initialValue !== '') {
    newValue = initialValue;
  }
  return {
    searchTerm: newValue,
  };
}

function searchReducer(state, action) {
  if (action.type === 'updateTerm') {
    return {
      searchTerm: action.nextTerm,
    };
  }
  throw Error('Unknown action.');
}

export const SearchInput = ({
  small,
  handleSearch,
  initialValue,
  focusHighlight,
  queryKey = null,
}) => {
  const [state, dispatch] = React.useReducer(searchReducer, initialValue, createInitialState);
  const inputRef = React.useRef();
  const useQueryKey = queryKey ?? 'name';

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (state.searchTerm !== '' && state.searchTerm !== initialValue) {
        handleSearch(useQueryKey, state.searchTerm, 'i_cont');
      }
    }, 750);

    if (initialValue !== '') {
      inputRef.current.focus();
    }

    return () => clearTimeout(delayDebounceFn);
  }, [state.searchTerm]);

  const handleInputChange = (e) => {
    dispatch({
      type: 'updateTerm',
      nextTerm: e.target.value,
    });
  };

  return (
    <Box className="relative">
      <StyledTextInput
        withIcon
        ref={inputRef}
        type="text"
        placeholder="Search"
        focusHighlight={focusHighlight}
        value={state.searchTerm}
        onChange={(e) => handleInputChange(e)}
        icon={(
          <StyledSVG
            src={localAsset('images/search-input-icon.svg')}
            width={small ? '12px' : '16px'}
            height={small ? '12px' : '16px'}
            title="Search"
          />
        )}
      />
      <StyledSearchInputClear
        plain
        icon={<FormClose size="1.25rem" color="#3C3C3C" />}
        onClick={() => handleSearch(useQueryKey, null, 'i_cont', false, true)}
        style={(state.searchTerm !== '') ? null : { display: 'none' }}
      />
    </Box>
  );
};

SearchInput.propTypes = {
  small: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  queryKey: PropTypes.string,
};

export const SortDropdown = ({
  textColor,
  handleSort,
  sortOptions,
  selectedSortOption = null,
}) => {
  let iconPath = 'images/sort-input-icon-desc.svg';
  const handleSelection = (sortValue) => {
    let useOrder = 'desc';
    if (selectedSortOption && selectedSortOption.direction) {
      useOrder = selectedSortOption.direction === 'asc' ? 'desc' : 'asc';
    }

    handleSort(sortValue, useOrder);
  };

  if (selectedSortOption && selectedSortOption.direction) {
    iconPath = `images/sort-input-icon-${selectedSortOption.direction}.svg`;
  }

  return (
    <SelectionDropdown
      options={sortOptions}
      handleSelection={handleSelection}
      label="Sort"
      iconPath={iconPath}
      textColor={textColor}
    />
  );
};

SortDropdown.propTypes = {
  textColor: PropTypes.string.isRequired,
  selectedSortOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  handleSort: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export const ModalSelectWrapper = ({
  small,
  setShowModal,
  handleQueryUpdate,
  textColor,
  buttonHighlight,
  children,
  label,
  modalBg = 'white',
  modalCloseMessage = 'Finished',
}) => (
  <StyledRaisedContainer
    gap="0.5rem"
    align="center"
    pad="1.5rem"
    width={{ min: small ? '96vw' : '50rem' }}
    background={modalBg}
  >
    <Box direction="row" width="100%" justify="between">
      <Text weight={600} size="1rem" color={textColor}>
        {label}
      </Text>
      <Button
        plain
        onClick={() => {
          handleQueryUpdate();
          setShowModal(false);
        }}
      >
        <StyledFormCloseContainer highlight={buttonHighlight} direction="row" gap="0.25rem" align="center">
          <Text weight={600} size="0.9rem" color={textColor}>
            Close
          </Text>
          <FormClose color={textColor} size="1.2rem" />
        </StyledFormCloseContainer>
      </Button>
    </Box>
    {children}
    <Box
      width="100%"
      pad={{ top: '1rem' }}
      align="center"
      border={{
        color: '#D0D0D0',
        size: '1px',
        style: 'solid',
        side: 'top',
      }}
    >
      <AppButton
        overrideHover
        width="7.25rem"
        height="3rem"
        onClick={() => {
          handleQueryUpdate();
          setShowModal(false);
        }}
        level="dynamicLarge"
        color="white"
        bgColor={buttonHighlight}
        fontWeight={600}
        label={modalCloseMessage}
      />
    </Box>
  </StyledRaisedContainer>
);

ModalSelectWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleQueryUpdate: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  modalBg: PropTypes.string,
  modalCloseMessage: PropTypes.string,
};

export const FilterDropdown = ({
  small,
  textColor,
  buttonHighlight,
  selectButtonBg,
  handleFilter,
  filterOptions,
  selectedFilters = null,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalKey, setModalKey] = React.useState(null);
  /* eslint-disable camelcase */
  const [selectedValues, setSelectedValues] = React.useState({
    status: null,
    platforms: [],
    start_date: [],
    end_date: [],
    result_count: [],
    view_count: [],
  });

  const findRangeValues = (key) => {
    const range = _.filter(selectedFilters, ((dt) => dt.name === key));

    const start = _.find(range, ['predicate', 'gteq'])?.value;
    const end = _.find(range, ['predicate', 'lteq'])?.value;

    if (start && end) {
      return [
        {
          name: key,
          value: start,
          predicate: 'gteq',
        },
        {
          name: key,
          value: end,
          predicate: 'lteq',
        },
      ];
    }

    return [];
  };

  React.useEffect(() => {
    if (selectedFilters) {
      const status = _.find(selectedFilters, ['name', 'status'])?.value;
      const platforms = _.find(selectedFilters, ['name', 'status'])?.value;

      setSelectedValues({
        status: status || null,
        platforms: platforms || [],
        start_date: findRangeValues('start_date'),
        end_date: findRangeValues('end_date'),
        result_count: findRangeValues('result_count'),
        view_count: findRangeValues('view_count'),
      });
    }
  }, [selectedFilters]);
  /* eslint-enable camelcase */

  const handleCategorySelect = (uid) => {
    if (uid) {
      setModalKey(uid);
      setShowModal(true);
    } else {
      handleFilter(modalKey, null, null, false, true);
    }
  };

  const handleMultiSelection = (uid) => {
    const prevSelected = { ...selectedValues };
    if (prevSelected[modalKey].includes(uid)) {
      _.pull(prevSelected[modalKey], uid);
    } else {
      prevSelected[modalKey].push(uid);
    }

    setSelectedValues(prevSelected);
  };

  const handleSingleSelection = (uid) => {
    const prevSelected = { ...selectedValues };
    prevSelected[modalKey] = uid;

    setSelectedValues(prevSelected);
  };

  const handleRangeSelection = (rangeStart, rangeEnd) => {
    const prevSelected = { ...selectedValues };
    const rangeArr = [];

    const newStart = {
      name: modalKey,
      value: rangeStart,
      predicate: 'gteq',
    };
    const newEnd = {
      name: modalKey,
      value: rangeEnd,
      predicate: 'lteq',
    };

    rangeArr.push(newStart, newEnd);
    prevSelected[modalKey] = rangeArr;

    setSelectedValues(prevSelected);
  };

  const currentFilter = _.find(filterOptions, ['name', modalKey]);

  const handleQueryUpdate = (seperateValues = false) => {
    const currentPredicate = currentFilter.validPredicates[0];
    const useValues = selectedValues[modalKey];

    if (useValues?.length > 0) {
      handleFilter(modalKey, useValues, currentPredicate, seperateValues);
    }
  };

  const { today } = React.useContext(CurrentDateContext);
  const defaultStartDate = format(subDays(new UTCDate(today), 2), 'yyyy-MM-dd');
  const defaultEndDate = format(addDays(new UTCDate(today), 2), 'yyyy-MM-dd');
  const useSeperate = modalKey === 'start_date' || modalKey === 'end_date'
    || modalKey === 'result_count' || modalKey === 'view_count';

  const rangeSelectLabel = (modalKey === 'result_count') ? 'Search results count' : 'View count';

  const renderModalContents = () => {
    const currentValue = selectedValues[modalKey];

    switch (modalKey) {
      case 'status':
        return (
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => handleQueryUpdate()}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            label="Search status"
          >
            <Box wrap direction="row">
              {currentFilter && currentFilter.validValues.map(({ name, formatted }) => (
                <Box key={uniqid()} pad={{ right: '1rem', vertical: '0.5rem' }}>
                  <StyledModalSelectButton
                    plain
                    selected={currentValue === name}
                    textSize="1rem"
                    onClick={() => handleSingleSelection(name)}
                    primaryText={textColor}
                    buttonHighlight={buttonHighlight}
                    buttonBg={selectButtonBg}
                    label={formatted}
                  />
                </Box>
              ))}
            </Box>
          </ModalSelectWrapper>
        );

      case 'platforms':
        return (
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => handleQueryUpdate()}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            label="Search platforms"
          >
            <Box wrap direction="row">
              {currentFilter && currentFilter.validValues.map(({ name, formatted }) => (
                <Box key={uniqid()} pad={{ right: '1rem', vertical: '0.5rem' }}>
                  <StyledModalSelectButton
                    plain
                    selected={currentValue.includes(name)}
                    textSize="1rem"
                    onClick={() => handleMultiSelection(name)}
                    primaryText={textColor}
                    buttonHighlight={buttonHighlight}
                    buttonBg={selectButtonBg}
                    label={formatted}
                  />
                </Box>
              ))}
            </Box>
          </ModalSelectWrapper>
        );

      case 'start_date':
      case 'end_date':
        return (
          <DateRangeCalendar
            className="authCalendar rangeCalendar"
            round="0.35rem"
            pad={{ top: '2rem', bottom: '0', horizontal: '0.5rem' }}
            buttonHighlight={buttonHighlight}
            setDateRange={(rangeStart, rangeEnd) => handleRangeSelection(rangeStart, rangeEnd)}
            initialDates={{
              startDate: currentValue[0]?.value || defaultStartDate,
              endDate: currentValue[1]?.value || defaultEndDate,
            }}
          />
        );

      case 'view_count':
      case 'result_count':
        return (
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => handleQueryUpdate(true)}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            label={rangeSelectLabel}
          >
            <Box width="100%" pad={{ vertical: '1rem' }}>
              <StyledRangeSelect
                label={(value) => shortenInt(value)}
                round="5px"
                min={0}
                max={10000}
                color={buttonHighlight}
                values={[currentValue[0]?.value || 0, currentValue[1]?.value || 10000]}
                onChange={(selected) => handleRangeSelection(selected[0], selected[1])}
              />
            </Box>
          </ModalSelectWrapper>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <SelectionDropdown
        options={filterOptions}
        handleSelection={handleCategorySelect}
        label="Filter"
        iconPath="images/filter-input-icon.svg"
        textColor={textColor}
      />
      {showModal && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => {
            handleQueryUpdate(useSeperate);
            setShowModal(false);
          }}
          onClickOutside={() => {
            handleQueryUpdate(useSeperate);
            setShowModal(false);
          }}
        >
          {renderModalContents(modalKey)}
        </Layer>
      )}
    </>
  );
};

FilterDropdown.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    predicate: PropTypes.string.isRequired,
  })),
  handleFilter: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
    validValues: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      formatted: PropTypes.string.isRequired,
    }).isRequired),
    validPredicates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired).isRequired,
};

export const FlexibleSelectDropdown = ({
  textColor,
  handleItemSelect,
  flexibleOptions,
  noSelectionLabel,
  displayKey,
  valueKey,
  buttonWidth = null,
  buttonHeight = null,
  buttonJustify = null,
  withReset = false,
  selectMenuBg = null,
  selectMenuHighlight = null,
  selectedOption = null,
}) => (
  <StyledFlexibleDrop
    formIcons
    height={buttonHeight}
    width={buttonWidth}
    withCloseDropContent={(onClose) => (
      <Box round="0.35rem" pad="12px">
        {flexibleOptions?.map((option) => (
          <StyledSelectMenu
            plain
            key={uniqid()}
            label={option[displayKey]}
            onClick={() => {
              handleItemSelect(option[valueKey]);
              onClose();
            }}
            hoverIndicator={selectMenuBg ?? '#F0F6FF'}
            highlightText={selectMenuHighlight ?? '#004FFE'}
          />
        ))}
        {withReset && selectedOption && (
          <StyledSelectMenu
            plain
            key="reset"
            label="Reset"
            onClick={() => {
              handleItemSelect(null);
              onClose();
            }}
            hoverIndicator={selectMenuBg ?? '#F0F6FF'}
            highlightText={selectMenuHighlight ?? '#004FFE'}
          />
        )}
      </Box>
    )}
  >
    {(Icon) => (
      <AppButton
        fill
        dropDown
        textSize="1rem"
        color={textColor}
      >
        <Box>
          <Box direction="row" gap="1rem" width="100%" justify={buttonJustify}>
            <Text color={textColor} size="1rem">
              {selectedOption ?? noSelectionLabel}
            </Text>
            <Box pad={{ top: '0.15rem' }}>
              <Icon color={textColor} size="1rem" />
            </Box>
          </Box>
        </Box>
      </AppButton>
    )}
  </StyledFlexibleDrop>
);

FlexibleSelectDropdown.propTypes = {
  textColor: PropTypes.string.isRequired,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleItemSelect: PropTypes.func.isRequired,
  displayKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  flexibleOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  noSelectionLabel: PropTypes.string.isRequired,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  buttonJustify: PropTypes.string,
  withReset: PropTypes.bool,
  selectMenuBg: PropTypes.string,
  selectMenuHighlight: PropTypes.string,
};

export const ReviewFilterDropdown = ({
  small,
  textColor,
  buttonHighlight,
  selectButtonBg,
  handleFilter,
  filterOptions,
  optionNames,
  selectedFilters = null,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalKey, setModalKey] = React.useState(null);
  const [selectedValues, setSelectedValues] = React.useState({
    domain: null,
    platforms: [],
  });

  React.useEffect(() => {
    if (selectedFilters) {
      setSelectedValues({
        domain: selectedFilters.domain || null,
        platforms: selectedFilters.platforms || [],
      });
    }
  }, [selectedFilters]);

  const handleCategorySelect = (uid) => {
    if (uid) {
      setModalKey(uid);
      setShowModal(true);
    } else {
      handleFilter(modalKey, null, true);
    }
  };

  const handleMultiSelection = (uid) => {
    const prevSelected = { ...selectedValues };
    if (prevSelected[modalKey].includes(uid)) {
      _.pull(prevSelected[modalKey], uid);
    } else {
      prevSelected[modalKey].push(uid);
    }

    setSelectedValues(prevSelected);
  };

  const handleSingleSelection = (uid) => {
    const prevSelected = { ...selectedValues };
    prevSelected[modalKey] = uid;

    setSelectedValues(prevSelected);
  };

  const currentFilter = _.find(filterOptions, ['name', modalKey]);

  const handleQueryUpdate = () => {
    const useValues = selectedValues[modalKey];

    if (useValues?.length > 0) {
      handleFilter(modalKey, useValues);
    }
  };

  const renderModalContents = () => {
    const currentValue = selectedValues[modalKey];

    switch (modalKey) {
      case 'domain':
        return (
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => handleQueryUpdate()}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            label="Result domains"
          >
            <Box direction="column" gap="0.5rem">
              <Text size="1rem" color={textColor}>Enter a partial or full domain to filter results:</Text>
              <ProductFormSingleTextInput
                key="domain"
                focusHighlight={buttonHighlight}
                small={small}
                value={selectedValues.domain || ''}
                handleFormValues={(val) => handleSingleSelection(val)}
              />
            </Box>
          </ModalSelectWrapper>
        );

      case 'platforms':
        return (
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => handleQueryUpdate()}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            label="Result platforms"
          >
            <Box wrap direction="row">
              {currentFilter && currentFilter.values.map(({ key, value }) => (
                <Box key={uniqid()} pad={{ right: '1rem', vertical: '0.5rem' }}>
                  <StyledModalSelectButton
                    plain
                    selected={currentValue.includes(key)}
                    textSize="1rem"
                    onClick={() => handleMultiSelection(key)}
                    primaryText={textColor}
                    buttonHighlight={buttonHighlight}
                    buttonBg={selectButtonBg}
                    label={value}
                  />
                </Box>
              ))}
            </Box>
          </ModalSelectWrapper>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <SelectionDropdown
        options={optionNames}
        handleSelection={handleCategorySelect}
        label="Filter"
        iconPath="images/filter-input-icon.svg"
        textColor={textColor}
      />
      {showModal && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => {
            handleQueryUpdate();
            setShowModal(false);
          }}
          onClickOutside={() => {
            handleQueryUpdate();
            setShowModal(false);
          }}
        >
          {renderModalContents(modalKey)}
        </Layer>
      )}
    </>
  );
};

ReviewFilterDropdown.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  selectedFilters: PropTypes.shape({
    domain: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  handleFilter: PropTypes.func.isRequired,
  optionNames: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired),
  }).isRequired).isRequired,
};

const ResultCountDropdown = ({
  textColor,
  handleResultsPerPage,
  resultsPerPageOptions,
  selectedResultsCount,
}) => (
  <StyledSimpleDrop
    formIcons
    withCloseDropContent={(onClose) => (
      <Box round="0.35rem" pad="12px">
        {resultsPerPageOptions.map((val) => (
          <StyledSelectMenu
            plain
            key={uniqid()}
            label={val}
            onClick={() => {
              handleResultsPerPage(val);
              onClose();
            }}
            hoverIndicator="#F0F6FF"
            highlightText="#004FFE"
          />
        ))}
      </Box>
    )}
  >
    {(Icon) => (
      <AppButton
        fill
        dropDown
        textSize="1rem"
        color={textColor}
        label={selectedResultsCount}
        icon={<Icon color={textColor} size="1rem" />}
      />
    )}
  </StyledSimpleDrop>
);

ResultCountDropdown.propTypes = {
  textColor: PropTypes.string.isRequired,
  selectedResultsCount: PropTypes.number.isRequired,
  handleResultsPerPage: PropTypes.func.isRequired,
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

const PaginationSelect = ({
  textColor,
  handlePage,
  currentPage,
  nextPageDisabled,
}) => {
  const paginate = (pageNum, direction) => {
    if (direction === 'back' && pageNum > 1) {
      handlePage(pageNum - 1);
    } else if (direction === 'forward') {
      handlePage(pageNum + 1);
    }
  };

  return (
    <Box direction="row" gap="0.25rem" align="center">
      <StyledPaginationArrow
        disabled={currentPage === 1}
        icon={<Previous size="0.9rem" color={textColor} />}
        onClick={() => paginate(currentPage, 'back')}
      />
      <Box
        background="white"
        width="1.6rem"
        height="1.6rem"
        pad="0.3rem"
        justify="center"
        align="center"
        border={{
          side: 'all', style: 'solid', size: '1px', color: '#D0D0D0',
        }}
      >
        <Text color={textColor} textAlign="center" size="0.85rem">{currentPage}</Text>
      </Box>
      <StyledPaginationArrow
        disabled={nextPageDisabled}
        icon={<Next size="0.9rem" color={textColor} />}
        onClick={() => paginate(currentPage, 'forward')}
      />
    </Box>
  );
};

PaginationSelect.propTypes = {
  textColor: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
  nextPageDisabled: PropTypes.bool.isRequired,
};

export const TableWrapper = ({
  small,
  boxPad,
  textColor,
  header,
  tableControls = null,
  children,
  currentResults = null,
  selectedResultsCount = null,
  totalResults = null,
  handleResultsPerPage = null,
  resultsPerPageOptions = null,
  isPaged = false,
  handlePage = null,
  currentPage = null,
  hidePagination = false,
  ...rest
}) => {
  const borderProps = { size: '1px', style: 'solid', side: 'bottom' };
  let displayResults = currentResults;
  let nextPageDisabled = false;

  if (isPaged) {
    const startIndex = (currentPage * selectedResultsCount) - (selectedResultsCount - 1);
    const end = Math.min(startIndex + (selectedResultsCount - 1), totalResults);
    displayResults = `${startIndex}-${end}`;

    if (currentPage * selectedResultsCount >= totalResults) {
      nextPageDisabled = true;
      displayResults = currentResults === 1 ? totalResults : `${startIndex}-${end}`;
    }
  }

  const resultsCount = displayResults && totalResults && `Viewing ${displayResults} of ${totalResults}`;
  const showPageOptions = resultsPerPageOptions && totalResults > resultsPerPageOptions[0];

  return (
    <Box background="white" pad={boxPad} {...rest}>
      <Box
        direction="row"
        justify="between"
        border={{ color: '#DDE2E4', ...borderProps }}
        margin={{ bottom: (tableControls && small) ? '0' : '0.6rem' }}
      >
        <Box
          flex={false}
          border={{ color: '#252C32', ...borderProps }}
          justify="end"
          pad={{ bottom: '0.5rem' }}
        >
          <Text color={textColor} weight={600} size="1rem">
            {header}
          </Text>
        </Box>
        {tableControls && !small && (
          <Box direction="row" gap="0.5rem" justify="end" pad={{ bottom: '1rem' }}>
            {tableControls}
          </Box>
        )}
      </Box>
      {tableControls && small && (
        <Box
          pad={{ vertical: '1rem' }}
          border={{
            color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
          }}
        >
          <Box direction="row" gap="0.5rem" justify="end">
            {tableControls}
          </Box>
        </Box>
      )}
      {children}
      {!hidePagination && (
        <Box
          width="100%"
          direction={small ? 'column' : 'row'}
          justify="between"
          align="center"
          gap="1rem"
          pad={{ top: small ? '1rem' : '1.5rem', left: small ? '0' : '0.5rem' }}
        >
          {resultsCount && (
            <Text color="#959595" size={small ? '0.9rem' : '0.75rem'} textAlign={small ? 'center' : 'start'}>
              {resultsCount}
            </Text>
          )}
          {handleResultsPerPage && resultsPerPageOptions && showPageOptions && (
            <Box
              direction="row"
              gap="3rem"
              pad={{ right: small ? '0' : '0.5rem' }}
              justify={(small && isPaged) ? 'between' : 'end'}
              width={small ? '100%' : '50%'}
            >
              <Box direction="row" gap="0.25rem" align="center">
                <Box align="start" justify="center">
                  <Text color={textColor} size={small ? '0.9rem' : '0.75rem'} style={{ whiteSpace: 'nowrap' }}>
                    Show per page
                  </Text>
                </Box>
                <ResultCountDropdown
                  textColor={textColor}
                  selectedResultsCount={selectedResultsCount}
                  handleResultsPerPage={handleResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                />
              </Box>
              {isPaged && (
                <Box flex={false} align="center" justify="end">
                  <PaginationSelect
                    textColor={textColor}
                    handlePage={handlePage}
                    currentPage={currentPage}
                    nextPageDisabled={nextPageDisabled}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

TableWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  boxPad: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any.isRequired),
  ]).isRequired,
  children: PropTypes.node.isRequired,
  tableControls: PropTypes.node,
  currentResults: PropTypes.number,
  selectedResultsCount: PropTypes.number,
  totalResults: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleResultsPerPage: PropTypes.func,
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  handlePage: PropTypes.func,
  isPaged: PropTypes.bool,
  currentPage: PropTypes.number,
  hidePagination: PropTypes.bool,
};
