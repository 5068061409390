import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
} from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { SettingsDemoContentPartial } from '@Components/Partial/Product/Cyclops';

import {
  StyledCyclopsHeading,
} from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';


const CyclopsSettingsPage = ({
  small,
  demoOnly,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyDemoData = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg, primaryText,
    },
  } = authPagesConfig;

  const renderPageContent = () => {
    if (demoOnly) {
      return (
        <SettingsDemoContentPartial
          small={small}
          authPagesConfig={authPagesConfig}
          companyDemoData={companyDemoData}
        />
      );
    }

    return null;
  };

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex background={primaryDarkBg} pad={small ? '2rem' : '3rem'} direction="column" gap="1.5rem">
        <StyledCyclopsHeading level={2} color={primaryText}>
          Cyclops Settings
        </StyledCyclopsHeading>
        {renderPageContent()}
      </Box>
    </AuthNavWrapper>
  );
};

CyclopsSettingsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  demoOnly: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyDemoData: PropTypes.objectOf(PropTypes.any),
};

export default withProductAuth(CyclopsSettingsPage);
