import React from 'react';
import PropTypes from 'prop-types';

import {
  startOfDay, addDays, addHours, addMinutes, format,
} from 'date-fns';
import { UTCDate } from '@date-fns/utc';

import {
  Box, Text, Drop,
} from 'grommet';
import { Previous, Next } from 'grommet-icons';
import Calendar from 'react-calendar';

import { AppButton } from '@Components/Control';
import { ProductFormInput } from '@Components/Partial/Product/NewSearch';

import {
  GridContainer,
  CalendarText,
  checkDatePresence,
  checkDateTimePresence,
  getTimeList,
} from './Component';
import { StyledDropMenu, StyledCalendarContainer } from './StyledCalendar';


const DEFAULT = '';

export default class SingleDateCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.timeInputRef = React.createRef();

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleTimeVisible = this.handleTimeVisible.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);

    this.state = {
      dateValue: props.date,
      time: (props.date || '').split('T')[1] || DEFAULT,
      timeVisible: false,
    };
  }

  handleDateChange(date) {
    const { setDate, dateFormat } = this.props;

    const formattedDate = checkDatePresence(date, dateFormat);
    setDate(formattedDate);
    this.setState({ dateValue: formattedDate });
  }

  handleDateTimeChange(date) {
    const { time } = this.state;
    const { setDate, dateFormat } = this.props;
    let newDateTime = null;

    if (time !== DEFAULT) {
      const [hours, minutes] = time.split(':');
      newDateTime = addHours(date, Number(hours));
      newDateTime = addMinutes(date, Number(minutes));
    }

    const formattedDate = checkDateTimePresence(newDateTime, dateFormat);
    setDate(formattedDate);

    this.setState({ dateValue: formattedDate });
  }

  handleTimeChange(time, onClose) {
    const { dateValue } = this.state;
    const { setDate, dateFormat } = this.props;

    if (onClose) onClose();

    if (time) {
      const [hours, minutes] = time.split(':');
      let useValue = dateValue ? dateValue.replaceAll('-', ',') : new UTCDate();
      if (dateValue && useValue && useValue.includes('T')) {
        useValue = useValue.substring(0, useValue.indexOf('T'));
      }
      let parsedDate = startOfDay(new UTCDate(useValue));
      parsedDate = addHours(parsedDate, Number(hours));
      parsedDate = addMinutes(parsedDate, Number(minutes));

      const formattedDate = checkDateTimePresence(parsedDate, dateFormat);
      setDate(formattedDate, true);
      this.setState({ dateValue: formattedDate, time, timeVisible: false });
    } else {
      const formattedDate = dateValue.substring(0, dateValue.indexOf('T'));
      setDate(formattedDate, true);
      this.setState({ dateValue: formattedDate, time: DEFAULT, timeVisible: false });
    }
  }

  handleTimeVisible(visible) {
    this.setState({ timeVisible: visible });
  }

  renderInputComponent(useAltTimeSelect) {
    const { time } = this.state;
    const { authPagesConfig: { focusHighlight } } = this.props;

    return (
      <ProductFormInput
        ref={this.timeInputRef}
        value={time}
        clearActive={time !== DEFAULT}
        onClick={() => this.handleTimeVisible(true)}
        onClear={() => this.handleTimeChange()}
        placeholder="select (optional)"
        className="app-calendar-time-input"
        highlight={useAltTimeSelect ? focusHighlight : null}
      />
    );
  }

  render() {
    const { dateValue, time, timeVisible } = this.state;
    const {
      minStartDate,
      maxDateRange,
      calendarFormat,
      useAltTimeSelect,
      handleClose,
      showCloseButton,
      authPagesConfig: {
        primaryText,
        buttonHighlight,
        selectButtonBg,
        iconHighlightColor,
        darkModeColors,
      },
      darkmode,
    } = this.props;
    const parsedMinStartDate = minStartDate && startOfDay(new UTCDate(minStartDate));
    const parsedMaxEndDate = minStartDate && startOfDay(addDays(new UTCDate(minStartDate), 1));
    const useDateValue = dateValue && dateValue.replace(/-/g, '/').replace(/T.+/, '');

    const tileDisabled = ({ date }) => {
      const dayStart = startOfDay(new UTCDate(date));

      return parsedMinStartDate >= dayStart || (maxDateRange && (dayStart > parsedMaxEndDate));
    };

    const usePrimaryText = darkmode ? darkModeColors.primaryText : primaryText;
    const useButtonHighlight = darkmode ? darkModeColors.menuButtonHighlight : buttonHighlight;
    const useSecondaryHighlight = darkmode ? darkModeColors.secondaryText : iconHighlightColor;
    const useContainerBg = darkmode ? darkModeColors.containerBg : 'white';
    const useTodayHighlight = darkmode ? '#02024A' : buttonHighlight;

    return (
      <StyledCalendarContainer
        align="center"
        className="app-calendar"
        bgColor={useContainerBg}
        primaryText={usePrimaryText}
        buttonHighlight={useButtonHighlight}
        secondaryHighlight={useSecondaryHighlight}
        todayHighlight={useTodayHighlight}
        {...this.props}
      >
        {calendarFormat === 'datetime' && this.timeInputRef && (
          <Box
            direction="row"
            width="calc(7 * 1.75rem + 26px)"
            pad={{ horizontal: '13px', bottom: '0.75rem', top: '0.25rem' }}
            align="center"
          >
            <Text size="1rem" wordBreak="keep-all" margin={{ right: '6px' }} color={usePrimaryText}>
              Time:&nbsp;
            </Text>
            {this.renderInputComponent(useAltTimeSelect)}
            {this.timeInputRef.current && (
              <Drop
                align={{ top: 'bottom', right: 'right' }}
                target={this.timeInputRef.current}
                onClickOutside={() => this.handleTimeVisible(false)}
                style={timeVisible ? { zIndex: 50 } : { display: 'none' }}
              >
                <Box
                  className="app-calendar-time-picker"
                  round="0.25rem"
                  height="10rem"
                  border={{ size: '1px', color: 'var(--light-grey)' }}
                  overflow="auto"
                >
                  {getTimeList().map((value) => (
                    <StyledDropMenu
                      plain
                      uppercase
                      key={value}
                      label={value}
                      onClick={() => this.handleTimeChange(value)}
                      color={primaryText}
                      highlightText={buttonHighlight}
                      hoverIndicator={selectButtonBg || 'rgba(125, 155, 212, 0.1)'}
                      selected={value === time}
                    />
                  ))}
                </Box>
              </Drop>
            )}
          </Box>
        )}
        <Calendar
          value={useDateValue}
          onChange={time === DEFAULT ? this.handleDateChange : this.handleDateTimeChange}
          calendarType="iso8601"
          navigationLabel={({ date }) => (
            <Box height="1.75rem" justify="center">
              <CalendarText weight={600} label={format(date, 'MMM yyyy')} />
            </Box>
          )}
          nextLabel={<GridContainer><Next size="small" color={useButtonHighlight} /></GridContainer>}
          next2Label={null}
          prevLabel={<GridContainer><Previous size="small" color={useButtonHighlight} /></GridContainer>}
          prev2Label={null}
          tileContent={({ date }) => (
            <GridContainer>
              <CalendarText label={format(date, 'd')} />
            </GridContainer>
          )}
          formatShortWeekday={(locale, date) => format(date, 'EEEEE')}
          showNeighboringMonth={false}
          view="month"
          minDetail="month"
          tileDisabled={tileDisabled}
        />
        {showCloseButton && handleClose && (
          <Box pad={{ bottom: '1rem' }}>
            <AppButton
              overrideHover
              onClick={() => handleClose()}
              label="Finished"
              level="dynamicSmall"
              color="white"
              bgColor={useButtonHighlight}
            />
          </Box>
        )}
      </StyledCalendarContainer>
    );
  }
}

SingleDateCalendar.defaultProps = {
  calendarFormat: undefined,
  date: undefined,
  dateFormat: undefined,
  datetimeFormat: undefined,
  minStartDate: undefined,
  maxDateRange: undefined,
  useAltTimeSelect: false,
  authPagesConfig: null,
  darkmode: false,
  showCloseButton: false,
};

SingleDateCalendar.propTypes = {
  calendarFormat: PropTypes.string,
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  dateFormat: PropTypes.string,
  datetimeFormat: PropTypes.string,
  minStartDate: PropTypes.string,
  maxDateRange: PropTypes.string,
  useAltTimeSelect: PropTypes.bool,
  darkmode: PropTypes.bool,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
